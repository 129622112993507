import 'bootstrap';

import '../styles/index.scss';


///
// Overview map
///

// get all images with the data-bs-target attribute
const images = document.querySelectorAll('[data-bs-target]');

images.forEach((image) => {
  // get the target element from the image's data-bs-target attribute
  const target = document.querySelector(image.dataset.bsTarget);

  // add an event listener to the target element when the accordion state changes
  target.addEventListener('hidden.bs.collapse', () => {
    // remove the 'show' class from the image's class list
    image.classList.remove('show');
  });

  target.addEventListener('shown.bs.collapse', () => {
    // add the 'show' class to the image's class list
    image.classList.add('show');
  });
});
